var reduce = Function.bind.call(Function.call, Array.prototype.reduce);
var isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
var concat = Function.bind.call(Function.call, Array.prototype.concat);
var keys = function(o) { return Object.getOwnPropertyNames(o).concat(Object.getOwnPropertySymbols(o))};

if (!Object.values) {
  Object.values = function values(O) {
    return reduce(keys(O), function (v, k) {
      return concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []);
    }, []);
  };
}

if (!Object.entries) {
  Object.entries = function entries(O) {
    return reduce(keys(O), function (e, k) {
      return concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []);
    }, []);
  };
}
